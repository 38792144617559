// import packages
import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

const initialState = {
  fName: "",
  email: "",
  password: "",
  confirmPassword: "",
  errors: {},
  success: false,
  loading: false
};

export default class AddAdmin extends Component {
  state = initialState;

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const user = {
      fName: this.state.fName,
      email: this.state.email,
      role: 0,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    };

    // set loading
    this.setState({ loading: true });

    // connect with server
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/register`, user)
      .then(result => {
        if (result) {
          // clear form
          this.setState(initialState);
          this.setState({ success: true, loading: false });
        }
      })
      .catch(err =>
        this.setState({ errors: err.response.data, loading: false })
      );
  };

  // close modal
  close = () => {
    this.setState({ success: false, errors: {} });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        <PageTitle title="Add Admin | Spritzer Blog" />
        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Settings
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Admin
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-3">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">ADD ADMIN</h6>
                </div>

                {this.state.success ? (
                  <SweetAlert
                    success
                    title="Successfull"
                    onConfirm={this.close}
                    confirmBtnText="Ok"
                    confirmBtnStyle={{
                      background: "#423c59",
                      border: 0,
                      width: 70
                    }}
                  >
                    Admin has been created successfully
                  </SweetAlert>
                ) : null}

                {this.state.errors.fail ? (
                  <SweetAlert
                    danger
                    title="Oops, Something went wrong"
                    onConfirm={this.close}
                    confirmBtnStyle={{ background: "#423c59", border: 0 }}
                  >
                    Admin hasn't been created successfully
                  </SweetAlert>
                ) : null}

                <form onSubmit={this.handleSubmit} noValidate>
                  <div className="form-group p-0 mt-4">
                    <label htmlFor="fName">
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control update-profile", {
                        "is-invalid": this.state.errors.fName
                      })}
                      id="fName"
                      name="fName"
                      value={this.state.fName}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.fName}
                    </div>
                  </div>

                  <div className="form-group p-0">
                    <label htmlFor="email">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className={classnames("form-control update-profile", {
                        "is-invalid": this.state.errors.email
                      })}
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.email}
                    </div>
                  </div>

                  <div className="form-group p-0">
                    <label htmlFor="pwd">
                      Password<span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className={classnames("form-control  update-profile", {
                        "is-invalid": this.state.errors.password
                      })}
                      id="pwd"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.password}
                    </div>
                  </div>
                  <div className="form-group p-0">
                    <label htmlFor="confirPwd">
                      Confirm Password<span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className={classnames("form-control update-profile", {
                        "is-invalid": this.state.errors.confirmPassword
                      })}
                      id="confirPwd"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.confirmPassword}
                    </div>
                  </div>
                  <button className="save-btn" type="submit" name="submit">
                    {this.state.loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {this.state.loading ? "  Registering..." : "Register"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
