// import packages
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

// initiate state
const initialState = {
  title: "",
  subTitle: "",
  country: "",
  category: "",
  date: "",
  countries: [],
  mainImage: "",
  tumbImage: "",

  success: "",
  errors: {},
  loading: false
};

export default class EditPost extends Component {
  state = initialState;

  componentDidMount = () => {
    // get edit data
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/post/` +
          this.props.match.params.id
      )
      .then(post => {
        // filter desc only
        this.setState({
          title: post.data.title,
          subTitle: post.data.subTitle,
          country: post.data.country._id,
          category: post.data.category,
          date: post.data.date,
        });
      });

    // get countries
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/countries`)
      .then(countries => {
        if (countries.data.length > 0) {
          this.setState({ countries: countries.data });
        }
      });
  };

  // get input value
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // get file value
  handleChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  // add product
  handleSubmit = e => {
    e.preventDefault();

    // create product object
    var bodyFormData = new FormData();
    bodyFormData.set("title", this.state.title);
    bodyFormData.set("subTitle", this.state.subTitle);
    bodyFormData.set("country", this.state.country);
    bodyFormData.set("category", this.state.category);
    bodyFormData.set("date", this.state.date);
    bodyFormData.append("mainImage", this.state.mainImage);
    bodyFormData.append("tumbImage", this.state.tumbImage);

    // set loading
    this.setState({ loading: true });

    // send post req
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/api/post/${
        this.props.match.params.id
      }/${isAuthenticated().user._id}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${isAuthenticated().token}`
      }
    })
      .then(result => {
        if (result) {
          // reset image value
          document.getElementById("mainImage").value = "";

          // set alert message
          this.setState({
            success: true,
            loading: false,
            title: "",
            subTitle: "",
            country: "",
            category: "",
            mainImage: "",
            tumbImage: "",
          });
        }
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  // redirect
  ok = () => {
    this.setState({ success: false, redirectToView: true });
  };


  render() {
    return (
      <div className="container-fluid p-0">
        <PageTitle title="Edit Blog Post | Spritzer Blog" />

        {this.state.redirectToView ? (
          <Redirect to="/dashboard/manage-post" />
        ) : null}

        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Post
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Edit Post
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-1">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">EDIT POST</h6>
                </div>

                {this.state.success ? (
                  <SweetAlert
                    success
                    title="Successfull"
                    onConfirm={this.ok}
                    confirmBtnText="Ok"
                    confirmBtnStyle={{
                      background: "#f26666",
                      border: 0,
                      width: 70
                    }}
                  >
                    Post is added successfully.
                  </SweetAlert>
                ) : null}

                {this.state.errors.fail ? (
                  <SweetAlert
                    danger
                    title="Oops, Something went wrong"
                    onConfirm={this.close}
                    confirmBtnStyle={{ background: "#f26666", border: 0 }}
                  >
                    Post isn't added successfully.
                  </SweetAlert>
                ) : null}

                <form className="mt-3" noValidate>
                  <div className="form-group p-0 mt-4">
                    <label htmlFor="title">
                      Title <b className="text-danger">*</b>
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      className={classnames("form-control w-100", {
                        "is-invalid": this.state.errors.title
                      })}
                      value={this.state.title}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.title}
                    </div>
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="subTitle">Sub-Title</label>
                    <input
                      type="text"
                      name="subTitle"
                      id="subTitle"
                      className="form-control"
                      value={this.state.subTitle}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="country">
                      Country <b className="text-danger">*</b>
                    </label>

                    <select
                      name="country"
                      className={classnames("form-control input-position", {
                        "is-invalid": this.state.errors.country
                      })}
                      id="country"
                      placeholder="-- select --"
                      value={this.state.country}
                      onChange={this.handleChange}
                    >
                      <option></option>
                      {this.state.countries.map(countries => (
                        <option key={countries._id} value={countries._id}>
                          {countries.country}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors.country}
                    </div>
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="category">
                      Category <b className="text-danger">*</b>
                    </label>

                    <select
                      name="category"
                      className={classnames("form-control input-position", {
                        "is-invalid": this.state.errors.category
                      })}
                      id="category"
                      placeholder="-- select --"
                      value={this.state.category}
                      onChange={this.handleChange}
                    >
                      <option></option>
                      <option>Both</option>
                      <option>User</option>
                      <option>Business</option>
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors.category}
                    </div>
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="date">
                      Posting Date <b className="text-danger">*</b>
                    </label>

                    <input
                      name="date"
                      type="date"
                      className={classnames("form-control input-position", {
                        "is-invalid": this.state.errors.date
                      })}
                      id="date"
                      value={this.state.date}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.date}
                    </div>
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="mainImage">
                      Header Image <b className="text-danger">*</b>
                    </label>
                    <input
                      type="file"
                      className="d-block"
                      name="mainImage"
                      id="mainImage"
                      accept="image/jpeg, image/png"
                      onChange={this.handleChangeFile}
                    />
                    <div className="mt-1 invalid-error">
                      {this.state.errors.mainImage}
                    </div>
                  </div>

                  {this.state.mainImage ? (
                    <div className="form-group p-0">
                      <img
                        src={URL.createObjectURL(this.state.mainImage)}
                        width="200"
                        alt="Front preview"
                      />
                    </div>
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/api/post/photo/${this.props.match.params.id}`}
                      width="200"
                      alt="Front preview"
                    />
                  )}

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="tumbImage">
                      Card Image <b className="text-danger">*</b>
                    </label>
                    <input
                      type="file"
                      className="d-block"
                      name="tumbImage"
                      id="tumbImage"
                      accept="image/jpeg, image/png"
                      onChange={this.handleChangeFile}
                    />
                    <div className="mt-1 invalid-error">
                      {this.state.errors.tumbImage}
                    </div>
                  </div>

                  {this.state.tumbImage ? (
                    <div className="form-group p-0">
                      <img
                        src={URL.createObjectURL(this.state.tumbImage)}
                        width="200"
                        alt="Front preview"
                      />
                    </div>
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/api/post/card/photo/${this.props.match.params.id}`}
                      width="200"
                      alt="Front preview"
                    />
                  )}

                </form>

                <div className="form-group p-0">
                  <button
                    className="save-btn mt-3"
                    type="submit"
                    name="submit"
                    onClick={this.handleSubmit}
                  >
                    {this.state.loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {this.state.loading ? " Saving..." : "Save"}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
