// import packages
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

export default class ViewCountry extends Component {
  state = {
    activeCountries: [],
    countries: [],
    deleteSuccess: ""
  };

  componentDidMount() {
    //get active countries for hide delete buttomn for them
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/post/active/countries`)
      .then(activeCountries => {
        if (activeCountries.data.length > 0) {
          this.setState({
            activeCountries: activeCountries.data
          });
        }
      });

    // get all countries
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/countries`)
      .then(countries => {
        if (countries.data.length > 0) {
          this.setState({
            countries: countries.data
          });
        }
      });
  }

  handleDelete = id => {
    axios.delete(
      `${process.env.REACT_APP_API_URL}/api/country/${id}/${
        isAuthenticated().user._id
      }`,
      {
        headers: {
          Authorization: `Bearer ${isAuthenticated().token}`
        }
      }
    );

    const filterCountries = this.state.countries.filter(
      countries => countries._id !== id
    );

    this.setState({
      countries: filterCountries,
      deleteSuccess: true
    });
  };

  // close modal
  close = () => {
    this.setState({ deleteSuccess: false, errors: {} });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        <PageTitle title="View country | Spritzer Blog" />

        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Country
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  View/Edit/Delete Country
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-3">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">VIEW/EDIT/DELETE COUNTRY</h6>
                </div>

                {this.state.deleteSuccess ? (
                  <SweetAlert
                    success
                    title="Successfull"
                    onConfirm={this.close}
                    confirmBtnText="Ok"
                    confirmBtnStyle={{
                      background: "#423c59",
                      border: 0,
                      width: 70
                    }}
                  >
                    Country is deleted successfully.
                  </SweetAlert>
                ) : null}

                <p className="pl-1 mb-2 mt-4" style={{ fontWeight: "600" }}>
                  {this.state.countries.length > 0
                    ? `Showing ${this.state.countries.length} countries`
                    : "No countries"}
                </p>

                <div className="table-responsive">
                  <table
                    id="zero_config"
                    className="table table-hover table-bordered"
                  >
                    <thead>
                      <tr className="view-table-tr-color">
                        <td width="120">Country ID</td>
                        <td>Country</td>
                        <td width="110">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.countries.map((countries, i) => (
                        <tr key={i}>
                          <td>{countries._id}</td>
                          <td>{countries.country}</td>
                          <td>
                            <Link
                              to={"/dashboard/edit-country/" + countries._id}
                            >
                              <button
                                type="submit"
                                className="btn btn-secondary btn-sm m-1"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </button>
                            </Link>

                            {`${this.state.activeCountries.filter(filter =>
                              filter === countries._id ? true : false
                            )}` ? null : (
                              <button
                                key={countries._id}
                                type="button"
                                className="btn btn-danger btn-sm m-1"
                                onClick={() => this.handleDelete(countries._id)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
