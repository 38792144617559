import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default function Cards(props) {
  return props.posts.map((post, i) => (
    <div key={i} className="blog-post-card">
      <div className="card-image-div">
        <Link to={`/${post._id}`}>
          <img
            src={`${process.env.REACT_APP_API_URL}/api/post/card/photo/${post._id}`}
            className="card-images"
            alt={post.title}
          />
        </Link>
      </div>
      <div className="title-div">
        <p className="title">{post.title}</p>
      </div>
      <div className="sub-title-div">
        <p className="sub-title">{post.subTitle}</p>
      </div>
      <div className="publish-date-div">
      <p className="publish-date pb-0">{moment(post.date).format("Do MMMM, YYYY")}</p>
      </div>
      <Link to={`/${post._id}`} className="read-more-btn">
        READ MORE...
      </Link>
    </div>
  ));
}
