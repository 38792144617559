// import packages
import React, { Component } from "react";
import axios from "axios";
import { Lines } from "react-preloaders";
import PhoneInput from "react-phone-input-2";

// import css
import "../../css/client/NavbarStyle.css";
import "../../css/client/homeStyle.css";

// import components
import Navbar from "../../components/client/Navbar";
import Footer from "../../components/client/Footer";
import Cards from "../../components/client/Cards";
import Pagination from "../../components/Pagination";
import PageTitle from "../../components/PageTitle";

export default class Home extends Component {
  state = {
    region: localStorage.getItem("regionName")
      ? localStorage.getItem("regionName")
      : "",
    countryCode: localStorage.getItem("countryCode")
      ? localStorage.getItem("countryCode")
      : "",
    CountryName: localStorage.getItem("CountryName")
      ? localStorage.getItem("CountryName")
      : "",

    posts: [],

    // pre loading
    loading: true,

    // pagination
    currentPage: 1,
    itemsPerPage: 9
  };

  componentDidMount = () => {
    
      if (!localStorage.getItem("regionName")) {
        // get all regions
        axios
          .get(`https://api.spritzer.app/services/regions`)
          .then(regions => {
            // filter regions which are isAvailable === true
            const filterRegions = regions.data.filter(filter => {
              return filter.isAvailable === true;
            });

            // get IP info
            axios
              .get(`https://api.spritzer.app/services/ipinfo`, {
                headers: {
                  secret: `7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS`
                }
              })
              .then(ipInfo => {
                var CountryName = "";
                var region = "";
                var countryCode = "";

                // filter by country code
                const countryCodeShort = filterRegions.filter(
                  codeShort =>
                    codeShort.country.countryCodeShort ===
                    ipInfo.data.countryCode
                );

                // identify country code
                if (countryCodeShort.length > 1) {
                  var regionCode = countryCodeShort.filter(
                    code => code.regionCode === ipInfo.data.regionCode
                  );
                  CountryName = regionCode[0].country.countryName;
                  region = regionCode[0].regionCode;
                  countryCode = regionCode[0].country.countryCodeShort;
                } else if (countryCodeShort.length === 1) {
                  CountryName = countryCodeShort[0].country.countryName;
                  region = countryCodeShort[0].regionCode;
                  countryCode = countryCodeShort[0].country.countryCodeShort;
                } else {
                  CountryName = "Australia";
                  region = "VIC";
                  countryCode = "AU";
                }

                // store in local storage
                localStorage.setItem("CountryName", CountryName);
                localStorage.setItem("regionName", region);
                localStorage.setItem("countryCode", countryCode);

                this.setState({ CountryName, region, countryCode });

                // get all posts
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/api/posts?sortBy=date&country=${countryCode}`
                  )
                  .then(posts => {
                    this.setState({
                      posts: posts.data,
                      loading: false
                    });

                  })
                  .catch(err => console.log(err));
              })
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      } else {
        // get all posts
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/posts?sortBy=date&country=${this.state.countryCode}`
          )
          .then(posts => {
            this.setState({
              posts: posts.data,
              loading: false
            });
          })
          .catch(err => console.log(err));
      }
    }

  // change region
  changeRegion = (CountryName, region, countryCode) => {
    this.setState({ CountryName, region, countryCode });

    // store in local storage
    localStorage.setItem("CountryName", CountryName);
    localStorage.setItem("regionName", region);
    localStorage.setItem("countryCode", countryCode);

    // get all posts
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/posts?sortBy=date&country=${countryCode}`
      )
      .then(posts => {
        this.setState({
          posts: posts.data,
          loading: false
        });
      })
      .catch(err => console.log(err));
  };

  // pagination change page
  handlePage = page => {
    this.setState({ currentPage: page });

    // scroll up to results
    if (window.innerWidth > 575) {
      window.scrollTo({ top: 200, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 250, behavior: "smooth" });
    }
  };

  render() {
    // pagination
    const indexOfLast = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirst = indexOfLast - this.state.itemsPerPage;
    const currentItems = this.state.posts.slice(indexOfFirst, indexOfLast);
    return (
      <React.Fragment>
        <PageTitle title="Spritzer | Blog" />
        <Navbar />

        <Lines
          color="#fff"
          background="#f26666"
          animation="slide-down"
          customLoading={this.state.loading}
        />

        <div
          className="jumbotron mb-0"
          style={{ background: "#fe6860", borderRadius: 0 }}
        >
          <div className="container">
            <div className="pt-5">
              <h1 className="text-center" style={{ color: "#fff" }}>
                Blog
              </h1>
            </div>
          </div>
        </div>

        <div className="container-fluid" style={{ background: "#f9f9f9" }}>
          <div className="container py-4 p-0">
            <div className="row m-0">
              <div className="col p-0">
                <p className="ml-0 ml-sm-3 mb-0" style={{ fontWeight: "600" }}>
                  {this.state.posts.length > 0
                    ? `Showing ${indexOfFirst + 1} - ${indexOfFirst +
                        currentItems.length} out of ${
                        this.state.posts.length
                      } posts`
                    : "No Posts"}
                </p>

                <Cards posts={currentItems} />

                <Pagination
                  className="m-0"
                  noOfAllItems={this.state.posts.length}
                  itemsPerPage={this.state.itemsPerPage}
                  currentPage={this.state.currentPage}
                  handlePage={this.handlePage}
                />
              </div>
            </div>
          </div>
        </div>

        <Footer
          CountryName={this.state.CountryName}
          countryCode={this.state.countryCode}
          changeRegion={this.changeRegion}
        />
      </React.Fragment>
    );
  }
}
