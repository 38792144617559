import React from "react";
import { Link } from "react-router-dom";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

export default function Sidebar() {
  // get authenticated user info
  const {
    user: { fName, image, _id }
  } = isAuthenticated();

  return (
    <div className="admin-sidebar-col">
      <Link to={`/dashboard/profile/${_id}`}>
        <img src={image} className="profile-img-sidebar" alt={fName} />
      </Link>
      <p className="sidebar-user-name">{fName}</p>
      <div className="accordion" id="accordion">
        <div className="card">
          <div
            className="card-header"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <span className="card-title">&nbsp; Posts</span>
          </div>

          <div id="collapseOne" className="collapse" data-parent="#accordion">
            <div className="card-body p-0">
              <Link
                to="/dashboard/add-post"
                className="list-group-item list-group-item-action justify-content-between py-3"
              >
                <i className="fas fa-plus"></i> &nbsp; Add
              </Link>
              <Link
                to="/dashboard/manage-post"
                className="list-group-item list-group-item-action justify-content-between py-3"
              >
                <i className="fas fa-pencil-alt"></i> &nbsp; View/Edit/Delete
              </Link>
            </div>
          </div>
        </div>

        <div className="card">
          <div
            className="card-header"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <span className="card-title">&nbsp; Country</span>
          </div>

          <div id="collapseTwo" className="collapse" data-parent="#accordion">
            <div className="card-body p-0">
              <Link
                to="/dashboard/add-country"
                className="list-group-item list-group-item-action justify-content-between pl-5 py-3"
              >
                <i className="fas fa-plus "></i> &nbsp; Add
              </Link>
              <Link
                to="/dashboard/view-country"
                className="list-group-item list-group-item-action justify-content-between pl-5 py-3"
              >
                <i className="fas fa-pencil-alt"></i> &nbsp; View/Edit/Delete
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
