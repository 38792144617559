import React, { Component } from 'react';
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import userApp from "../../images/user-iphoneX.png";
import apple from "../../images/icon-apple-store--black.png";
import google from "../../images/icon-google-play--black.png";

import "../../css/client/getApp.css"

export default class GetTheAppModel extends Component {
    state = {
        phone: "",
    
        // message
        success: false,
        fail: false,
    
        // loading
        loading: false
      };
    
      // componentDidMount = () => {
      //   // get all regions
      //   axios
      //     .get(`https://api.spritzer.app/services/regions`)
      //     .then(regions => {
      //       // filter regions which are isAvailable === true
      //       const filterRegions = regions.data.filter(filter => {
      //         return filter.isAvailable === true;
      //       });
    
      //       // get IP info
      //       axios
      //         .get(`https://api.spritzer.app/services/ipinfo`, {
      //           headers: {
      //             secret: `7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS`
      //           }
      //         })
      //         .then(ipInfo => {
      //           var countryCode = "";
    
      //           // filter by country code
      //           const countryCodeShort = filterRegions.filter(
      //             codeShort =>
      //               codeShort.country.countryCodeShort === ipInfo.data.countryCode
      //           );
    
      //           // identify country code
      //           if (countryCodeShort.length > 1) {
      //             var regionCode = countryCodeShort.filter(
      //               code => code.regionCode === ipInfo.data.regionCode
      //             );
      //             countryCode = regionCode[0].country.countryCodeShort;
      //           } else if (countryCodeShort.length === 1) {
      //             countryCode = countryCodeShort[0].country.countryCodeShort;
      //           } else {
      //             countryCode = "AU";
      //           }
    
      //           this.setState({ countryCode: countryCode });
      //         })
      //         .catch(err => console.log(err));
      //     })
      //     .catch(err => console.log(err));
      // };
    
      sendAppLink = () => {
        if (this.state.phone.length === 15) {
          // set loading
          this.setState({ loading: true });
    
          axios
            .get(`https://api.spritzer.app/services/send-applink`, {
              params: {
                to: this.state.phone,
                type: "user"
              },
              headers: {
                secret:
                  "7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS"
              }
            })
            .then(res => {
              this.setState({ success: true, loading: false });
            })
            .catch(err => {
              this.setState({ fail: true, loading: false });
            });
        }
      };
    
    render() {
        return (
            <div
          className="modal fade get-the-app"
          id="get-the-app"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered get-the-app"
            role="document"
          >
            <div
              className="modal-content"
              style={{
                minWidth: "100vw",
                minHeight: "100vh",
                margin: 0,
                backgroundImage: "linear-gradient(#FF6961, #b34444)"
              }}
            >
              <div className="modal-header" style={{ border: 0 }}>
                <button
                  type="button"
                  className="close  modal-close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row modal-content-row">
                    <div className="col-md img-col">
                      <img src={userApp} className="user-app-img" />
                    </div>
                    <div className="col-md modal-content-col">
                      <h2 className="tagline">
                        Happy Hours,
                        <br />
                        <span
                          style={{
                            fontSize: "0.5em",
                            letterSpacing: "3px",
                            fontWweight: "normal"
                          }}
                        >
                          whenever you want 'em
                        </span>
                      </h2>
                      {this.state.success || this.state.fail ? (
                        this.state.success ? (
                          <p className="success-fail-message">
                            <i className="fas fa-check"> </i> App Link Sent
                          </p>
                        ) : (
                          <p className="success-fail-message">
                            <i className="fas fa-times"> </i> Opps! Something
                            Went Wrong
                          </p>
                        )
                      ) : (
                        <React.Fragment>
                          <p>Send me the app link</p>
                          <div className="send-phone-nub-div">
                            <PhoneInput
                              country={`${localStorage.getItem("countryCode") &&
                                localStorage
                                  .getItem("countryCode")
                                  .toLowerCase()}`}
                              enableSearch={true}
                              value={this.state.phone}
                              onChange={phone => this.setState({ phone })}
                            />
                            <button
                              className="send-btn"
                              onClick={this.sendAppLink}
                            >
                              {this.state.loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : null}

                              {this.state.loading ? " Sending..." : "Send"}
                            </button>
                          </div>
                        </React.Fragment>
                      )}
                      <div className="store-div">
                        <a
                          href="https://itunes.apple.com/au/app/spritzer-happy-hours/id1457489918?mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={apple}
                            className="store-icons"
                            alt="Download user app from apple store"
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.spritzer.user"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={google}
                            className="store-icons"
                            alt="Download user app from google play store"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
     
     
        )
    }
}
