// import packages
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import Pagination from "../../components/Pagination";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

export default class ManagePost extends Component {
  state = {
    posts: [],
    deleteSuccess: false,

    // pagination
    currentPage: 1,
    itemsPerPage: 24
  };

  componentDidMount() {
    // get all products
    axios.get(`${process.env.REACT_APP_API_URL}/api/posts`).then(posts => {
      if (posts.data.length > 0) {
        this.setState({
          posts: posts.data
        });
      }
    });
  }

  // delete product
  handleDelete = id => {
    // send delete req
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/post/${id}/${
          isAuthenticated().user._id
        }`,
        {
          headers: { Authorization: `Bearer ${isAuthenticated().token}` }
        }
      )
      .then(result => {
        const filterPosts = this.state.posts.filter(post => post._id !== id);

        // clear state
        this.setState({
          posts: filterPosts,
          deleteSuccess: true,
          show: false
        });
      });
  };

  // pagination change page
  handlePage = page => {
    this.setState({ currentPage: page });

    // scroll up to results
    if (window.innerWidth > 575) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 2000, behavior: "smooth" });
    }
  };

  // close modal
  close = () => {
    this.setState({ deleteSuccess: false, errors: {} });
  };

  render() {
    // pagination
    // get current products
    const indexOfLast = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirst = indexOfLast - this.state.itemsPerPage;
    const currentItems = this.state.posts.slice(indexOfFirst, indexOfLast);

    return (
      <div className="container-fluid p-0">
        <PageTitle title="Manage Blog Post | Spritzer Blog" />

        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Post
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  View/Edit/Delete Post
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-3">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">VIEW/EDIT/DELETE POST</h6>
                </div>

                {this.state.deleteSuccess ? (
                  <SweetAlert
                    success
                    title="Successfull"
                    onConfirm={this.close}
                    confirmBtnText="Ok"
                    confirmBtnStyle={{
                      background: "#423c59",
                      border: 0,
                      width: 70
                    }}
                  >
                    Post is deleted successfully.
                  </SweetAlert>
                ) : null}

                <p className="pl-1 mb-2 mt-4" style={{ fontWeight: "600" }}>
                  {this.state.posts.length > 0
                    ? `Showing ${indexOfFirst + 1} - ${indexOfFirst +
                        currentItems.length} out of ${
                        this.state.posts.length
                      } results`
                    : "No inventory"}
                </p>

                <div className="table-responsive">
                  <table className="table table-hover table-bordered mb-0">
                    <thead>
                      <tr className="view-table-tr-color">
                        <td>Title</td>
                        <td>Sub-Title</td>
                        <td>Posting Date</td>
                        <td>Country</td>
                        <td>Category</td>
                        <td>Main Image</td>
                        <td>Tumb Image</td>
                        <td width="160px">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((post, i) => (
                        <tr key={i}>
                          <td>{post.title}</td>
                          <td>{post.subTitle ? post.subTitle : null}</td>
                          <td>{post.date} </td>
                          <td>{post.country.country} </td>
                          <td>{post.category} </td>
                          <td align="center">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/api/post/photo/${post._id}`}
                              width="100px"
                              className="py-1"
                              alt={post.title}
                            />
                          </td>
                          <td align="center">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/api/post/card/photo/${post._id}`}
                              width="100px"
                              className="py-1"
                              alt={post.title}
                            />
                          </td>
                          <td>
                            <Link
                              to={"/dashboard/view-post/" + post._id}
                              className="btn btn-info btn-sm m-1"
                            >
                              <i className="fa fa-info px-1"></i>
                            </Link>

                            <Link
                              to={"/dashboard/edit-post/" + post._id}
                              className="btn btn-secondary btn-sm m-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>

                            <button
                              type="button"
                              onClick={() => this.handleDelete(post._id)}
                              className="btn btn-danger btn-sm m-1"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* starts of pagination */}
                <Pagination
                  className="m-0"
                  noOfAllItems={this.state.posts.length}
                  itemsPerPage={this.state.itemsPerPage}
                  currentPage={this.state.currentPage}
                  handlePage={this.handlePage}
                />
                {/* ends of pagination */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
