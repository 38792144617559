// import packages
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

// initiate state
const initialState = {
  fName: "",
  errors: {},
  success: false,
  fail: false,
  directToAdminProfile: false
};

export default class EditAdminProfile extends Component {
  state = initialState;

  componentDidMount = () => {
    this.setState({
      fName: isAuthenticated() && isAuthenticated().user.fName
    });
  };

  // handle inputs
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const userData = {
      fName: this.state.fName
    };

    console.log(userData);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/user/update/${
          isAuthenticated().user._id
        }`,
        userData,
        {
          headers: { Authorization: `Bearer ${isAuthenticated().token}` }
        }
      )
      .then(user => {
        if (user) {
          if (typeof window !== "undefined") {
            if (localStorage.getItem("jwt")) {
              let auth = JSON.parse(localStorage.getItem("jwt"));

              // update user data
              auth.user = user.data;

              // set updated jwt
              localStorage.setItem("jwt", JSON.stringify(auth));
            }
          }

          this.setState(initialState);
          this.setState({ success: true });
        }
      })
      .catch(err => this.setState({ errors: err.response.data }));
  };

  // close modal
  ok = () => {
    this.setState({ directToAdminProfile: true });
  };

  // close modal
  close = () => {
    this.setState({ success: false, errors: {} });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        <PageTitle title="Edit Admin Profile | Spritzer Blog" />

        {this.state.directToAdminProfile ? (
          <Redirect to={`/dashboard/profile/${this.props.match.params.id}`} />
        ) : null}

        {this.state.success ? (
          <SweetAlert
            success
            title="Successfull"
            onConfirm={this.ok}
            confirmBtnText="Ok"
            confirmBtnStyle={{
              background: "#423c59",
              border: 0,
              width: 70
            }}
          >
            Your profile is updated successfully.
          </SweetAlert>
        ) : null}

        {this.state.errors.fail ? (
          <SweetAlert
            danger
            title="Oops, Something went wrong"
            onConfirm={this.close}
            confirmBtnStyle={{ background: "#423c59", border: 0 }}
          >
            Your profile isn't updated successfully.
          </SweetAlert>
        ) : null}

        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Profile
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Update Profile
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-3">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">PROFILE</h6>
                </div>

                <div className="table table-responsive mt-4">
                  <table className="profile-table">
                    <tbody>
                      <tr style={{ height: "40px" }}>
                        <td style={{ width: "150pxpx" }}>First Name</td>
                        <td>
                          <input
                            type="text"
                            name="fName"
                            className={classnames("form-control", {
                              "is-invalid": this.state.errors.fName
                            })}
                            value={this.state.fName}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                            {this.state.errors.fName}
                          </div>
                        </td>
                      </tr>
                      <tr style={{ height: "40px" }}>
                        <td>E-mail</td>
                        <td>
                          {isAuthenticated() && isAuthenticated().user.email}
                        </td>
                      </tr>
                      <tr style={{ height: "40px" }}>
                        <td></td>
                        <td>
                          <button
                            className="save-btn"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
