import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/spritzer-logo.png";

import GetTheAppModal from "./GetTheAppModal";

export default class Navbar extends Component {
  state = {
    icon: false
  };

  changeIcon = () => {
    this.setState({ icon: !this.state.icon });
  };

  render() {
    return (
      <React.Fragment>
        <nav className="navbar sticky-top navbar-expand-md pb-2 pt-3 navbar-client">
          <a
            href="https://spritzer.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logo}
              width="170"
              id="com-logo-non-mobile"
              alt="ransis arcade logo"
              className="mr-3 py-2"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.changeIcon}
          >
            <span className="navbar-toggler-icon">
              {this.state.icon ? (
                <i className="fas fa-times float-right"></i>
              ) : (
                <i className="fas fa-bars float-right"></i>
              )}
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto nav-links-mobile">
              <li className="nav-item">
                <a
                  href="https://business.spritzer.app/"
                  className="nav-item spritzer-for-bus-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "inline-block", marginTop: 14 }}
                >
                  Spritzer for Business
                </a>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#get-the-app"
                  className="nav-item get-app-btn"
                >
                  Get the App
                </button>
              </li>
            </ul>
          </div>
        </nav>

        <GetTheAppModal />
      </React.Fragment>
    );
  }
}
