// import packages
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

import PageTitle from "../../components/PageTitle";

// import css
import "../../css/client/UserLogin.css";

export default class Login extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
    loginFail: false,
    forgetPasswordSuccess: false,
    forgetPasswordFail: false,
    redirectTo: false,
    loading: false
  };

  // handle inputs
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  // login
  handleSubmit = e => {
    e.preventDefault();

    // create login data object
    const loginCredentials = {
      email: this.state.email,
      password: this.state.password
    };

    // set loading
    this.setState({ loading: true });

    // send post request
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/login`, loginCredentials)
      .then(result => {
        if (result) {
          // set jwt on local storage
          if (typeof window !== "undefined") {
            localStorage.setItem("jwt", JSON.stringify(result.data));
          }

          // clear form
          this.setState({
            email: "",
            password: "",
            redirectTo: true,
            errors: {},
            loading: false
          });
        }
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });

        if (err.response.data.message) {
          // fail state
          this.setState({ loginFail: true });
        }
      });
  };

  // close modal
  close = () => {
    this.setState({ loginFail: false, errors: {} });
  };

  render() {
    return (
      <React.Fragment>
        <PageTitle title="Login | Spritzer Blog" />
        <div
          className="container-fluid"
          style={{ background: "#f26666", height: "100vh" }}
        >
          {this.state.redirectTo ? <Redirect to="/dashboard/main" /> : null}

          {this.state.loginFail ? (
            <SweetAlert
              danger
              title="Oops, Something went wrong"
              onConfirm={this.close}
              confirmBtnStyle={{ background: "#423c59", border: 0, width: 70 }}
            >
              Invalid username or password!
            </SweetAlert>
          ) : null}

          <form noValidate className="login-form">
            <h3
              className="text-center mb-3"
              style={{ fontWeight: 600, color: "#f26666" }}
            >
              Member Login
            </h3>
            <div className="form-group p-0">
              <label htmlFor="email" style={{ fontWeight: "600" }}>
                Email
              </label>
              <input
                type="email"
                className={classnames("form-control", {
                  "is-invalid": this.state.errors.email
                })}
                id="email"
                name="email"
                onChange={this.handleChange}
                value={this.state.email}
              />
              <div className="invalid-feedback">{this.state.errors.email}</div>
            </div>
            <div className="form-group p-0 mb-0">
              <label htmlFor="inputPassword4" style={{ fontWeight: "600" }}>
                Password
              </label>
              <input
                type="password"
                className={classnames("form-control", {
                  "is-invalid": this.state.errors.password
                })}
                id="pwd"
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
              />
              <div className="invalid-feedback">
                {this.state.errors.password}
              </div>
            </div>
            <button
              type="submit"
              className="save-btn w-100 mt-3"
              onClick={this.handleSubmit}
            >
              {this.state.loading ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}
              {this.state.loading ? " Logging..." : <span> Login</span>}
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
