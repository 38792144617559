import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Home from "./pages/client/Home";
import singleBlog from "./pages/client/singleBlog";

import Login from "./pages/client/Login";
// import Register from "./pages/client/Register";

// import owner pages
import AddAdmin from "./pages/admin/AddAdmin";
import ViewAdminUsers from "./pages/admin/ViewAdminUsers";

// profiles
import AdminProfile from "./pages/admin/AdminProfile";
import EditAdminProfile from "./pages/admin/EditAdminProfile";

// import admin pages
import Dashboard from "./pages/admin/Dashboard";
import AddPost from "./pages/admin/AddPost";
import ManagePost from "./pages/admin/ManagePost";
import ViewPost from "./pages/admin/ViewPost";
import EditPost from "./pages/admin/EditPost";

import AddCountry from "./pages/admin/AddCountry";
import ViewCountry from "./pages/admin/ViewCountry";
import EditCountry from "./pages/admin/EditCountry";

// import 404 page
import PageNotFound from "./pages/client/PageNotFound";

// load isAuthenticated method
import { isAuthenticated } from "./auth/auth.js";

// create a owner route
const OwnerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && isAuthenticated().user.role === 1 ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

// create a admmin router
const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (isAuthenticated() && isAuthenticated().user.role === 0) ||
      (isAuthenticated() && isAuthenticated().user.role === 1) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/:id" exact component={singleBlog} />

        <OwnerRoute
          path="/dashboard/settings/add-admin"
          exact
          component={AddAdmin}
        />
        <OwnerRoute
          path="/dashboard/settings/view-all-admin-users"
          exact
          component={ViewAdminUsers}
        />

        <AdminRoute
          path="/dashboard/profile/:id"
          exact
          component={AdminProfile}
        />
        <AdminRoute
          path="/dashboard/update-admin-profile/:id"
          exact
          component={EditAdminProfile}
        />

        <AdminRoute path="/dashboard/main" exact component={Dashboard} />
        <AdminRoute path="/dashboard/add-post" exact component={AddPost} />
        <AdminRoute
          path="/dashboard/manage-post"
          exact
          component={ManagePost}
        />
        <AdminRoute
          path="/dashboard/view-post/:id"
          exact
          component={ViewPost}
        />
        <AdminRoute
          path="/dashboard/edit-post/:id"
          exact
          component={EditPost}
        />

        <AdminRoute
          path="/dashboard/add-country"
          exact
          component={AddCountry}
        />
        <AdminRoute
          path="/dashboard/view-country"
          exact
          component={ViewCountry}
        />
        <AdminRoute
          path="/dashboard/edit-country/:id"
          exact
          component={EditCountry}
        />

        <Route path="/404" exact component={PageNotFound} />
        <Redirect to="404" />
      </Switch>
    </Router>
  );
}

export default App;
