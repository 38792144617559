import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import logo from "../../images/spritzer-logo-white-bg.png";
import axios from "axios";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

export default class Navbar extends Component {
  state = {
    _id: "",
    fName: "",
    image: "",
    role: ""
  };

  componentDidMount = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/adminUser/${
          isAuthenticated().user._id
        }`
      )
      .then(result => {
        this.setState({
          _id: result.data[0]._id,
          fName: result.data[0].fName,
          image: result.data[0].image,
          role: result.data[0].role
        });
      })
      .catch(err => console.log(err));
  };

  handleLogout = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/logout`)
      .then(result => {
        if (result) {
          if (typeof window !== "undefined") {
            localStorage.removeItem("jwt");
          }

          this.setState({ logoutSuccessfully: true });
        }
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <nav className="navbar sticky-top navbar-expand-sm admin-navbar">
        {this.state.logoutSuccessfully === true ? (
          <Redirect to="/login" />
        ) : null}

        <Link to="/dashboard/main">
          <img src={logo} width="150" alt="ransis arcade logo" />
        </Link>
        <img
          src={this.state.image}
          className="rounded-circle user-img"
          id="user-img-mobile"
          alt="user img mobile"
        />
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarAdmin"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars text-secondary"></i>
        </button>

        <div
          className="collapse navbar-collapse admin-nav-bar"
          id="navbarAdmin"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <span className="nav-link">
                Role : {this.state.role === 1 ? "Admin" : "Owner"}
              </span>
            </li>
            {this.state.role === 1 ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-light"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-cog"></i> Setting
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link
                    className="dropdown-item"
                    to="/dashboard/settings/add-admin"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;
                    &nbsp; Add Admin
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/dashboard/settings/view-all-admin-users"
                  >
                    <i className="fas fa-pencil-alt" aria-hidden="true"></i>{" "}
                    &nbsp; &nbsp; View All Admins
                  </Link>
                </div>
              </li>
            ) : null}
            <li className="nav-item active">
              <Link
                to={`/dashboard/profile/${this.state._id}`}
                className="nav-link"
              >
                Profile
              </Link>
            </li>
            <li className="nav-item">
              <span className="nav-link text-light">Hi {this.state.fName}</span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link text-light"
                onClick={this.handleLogout}
                style={{ cursor: "pointer" }}
              >
                Logout
              </span>
            </li>
            <li className="nav-item">
              <img
                src={this.state.image}
                className="rounded-circle user-img"
                id="user-img-non-mobile"
                alt="user img non mobile"
              />
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
