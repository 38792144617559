// import packages
import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

// initiate the category
const initialState = {
  country: "",
  errors: {},
  success: false
};

export default class AddCountry extends Component {
  state = initialState;

  // get input values
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // add category
  handleSubmit = e => {
    e.preventDefault();

    // create data object
    const newCountry = {
      country: this.state.country
    };

    // set loading
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/country/${
          isAuthenticated().user._id
        }`,
        newCountry,
        {
          headers: { Authorization: `Bearer ${isAuthenticated().token}` }
        }
      )
      .then(result => {
        if (result) {
          // clear form
          this.setState(initialState);

          // set alert message
          this.setState({ success: true, loading: false });
        }
      })
      .catch(err =>
        this.setState({ errors: err.response.data, loading: false })
      );
  };

  // close modal
  close = () => {
    this.setState({ success: false, errors: {} });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        <PageTitle title="Add Country | Spritzer Blog" />
        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Country
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Country
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">ADD Country</h6>
                </div>

                {this.state.success ? (
                  <SweetAlert
                    success
                    title="Successfull"
                    onConfirm={this.close}
                    confirmBtnText="Ok"
                    confirmBtnStyle={{
                      background: "#423c59",
                      border: 0,
                      width: 70
                    }}
                  >
                    Country is added successfully.
                  </SweetAlert>
                ) : null}

                {this.state.errors.fail ? (
                  <SweetAlert
                    danger
                    title="Oops, Something went wrong"
                    onConfirm={this.close}
                    confirmBtnStyle={{ background: "#423c59", border: 0 }}
                  >
                    Country isn't added successfully.
                  </SweetAlert>
                ) : null}

                <form onSubmit={this.handleSubmit} className="mt-3" noValidate>
                  <div className="form-row">
                    <div className="col-md-12 mb-3 form-group row">
                      <label htmlFor="country" className="col-form-label">
                        Country <b className="text-danger">*</b>
                      </label>
                      <div className="col-input">
                        <input
                          type="text"
                          name="country"
                          className={classnames("form-control", {
                            "is-invalid": this.state.errors.country
                          })}
                          id="country"
                          value={this.state.country}
                          onChange={this.handleChange}
                        />
                        <div className="invalid-feedback">
                          {this.state.errors.country}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mb-0 form-group row">
                      <label className="col-form-label"></label>
                      <div className="col-input">
                        <button
                          className="save-btn"
                          type="submit"
                          name="submit"
                        >
                          {this.state.loading ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : null}
                          {this.state.loading ? " Saving..." : "Save"}
                        </button>
                      </div>
                    </div>

                    <div className="col-md-12 mt-2 mb-3 form-group row">
                      <label className="col-form-label"></label>
                      <div className="col-input">
                        <b className="text-danger" style={{ fontSize: "14px" }}>
                          * Denotes required.
                        </b>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
