// import packages
import React, { Component } from "react";
import axios from "axios";

// import css
import "../../css/admin/commonStyle.css";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// import CanvasJSReact
import CanvasJSReact from "../../assets/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Dashboard extends Component {
  state = {
    countries: [],
    categories: []
  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/country-wise-posts`)
      .then(countries => {
        const sortCountries = countries.data.sort((a, b) => {
          return a._id.country.localeCompare(b._id.country);
        });

        this.setState({
          countries: sortCountries
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/category-wise-posts`)
      .then(categories => {
        const sortCategories = categories.data.sort((a, b) => {
          return a._id.category.localeCompare(b._id.category);
        });
        this.setState({
          categories: sortCategories
        });
      });
  }

  render() {
    // calculate the inventory
    const total = this.state.countries.reduce((currentValue, nextValue) => {
      return currentValue + nextValue.count;
    }, 0);

    // country
    let pieValues = [];

    this.state.countries.map((countries, i) =>
      pieValues.push({
        y: ((countries.count / total) * 100).toFixed(2),
        label: countries._id.country
      })
    );

    // CanvasJSReact
    const options = {
      exportEnabled: true,
      animationEnabled: true,
      data: [
        {
          type: "pie",
          startAngle: 75,
          toolTipContent: "<b>{label}</b>: {y}%",
          showInLegend: "true",
          legendText: "{label}",
          indexLabelFontSize: 16,
          indexLabel: "{label} - {y}%",
          dataPoints: pieValues
        }
      ]
    };

    // category
    let pieValuesCat = [];

    this.state.categories.map((categories, i) =>
      pieValuesCat.push({
        y: ((categories.count / total) * 100).toFixed(2),
        label: categories._id.category
      })
    );

    // CanvasJSReact
    const optionsCat = {
      exportEnabled: true,
      animationEnabled: true,
      data: [
        {
          type: "pie",
          startAngle: 75,
          toolTipContent: "<b>{label}</b>: {y}%",
          showInLegend: "true",
          legendText: "{label}",
          indexLabelFontSize: 16,
          indexLabel: "{label} - {y}%",
          dataPoints: pieValuesCat
        }
      ]
    };
    return (
      <div className="container-fluid p-0">
        <PageTitle title="Admin Dashboard | Spritzer Blog" />
        <Navbar />
        <div className="row m-0">
          <Sidebar />
          <div className="content-col p-0">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Dashboard
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-3">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">COUNTRY WISE POSTS</h6>
                </div>
                <div className="row">
                  <div className="col">
                    <CanvasJSChart options={options} />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="table-responsive mt-4">
                      <table className="table table-bordered">
                        <tbody>
                          <tr align="center" className="view-table-tr-color">
                            <td width="175px">Country</td>
                            <td width="175px">No. of Posts</td>
                            <td width="175px">%</td>
                          </tr>

                          {this.state.countries.map((country, i) => (
                            <tr align="center" key={i}>
                              <td>{country._id.country}</td>
                              <td>{country.count}</td>
                              <td>
                                {((country.count / total) * 100).toFixed(2)}%
                              </td>
                            </tr>
                          ))}

                          <tr className="font-weight-bold" align="center">
                            <td>Total</td>
                            <td>{total}</td>
                            <td>100.00%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">Category WISE POSTS</h6>
                </div>
                <div className="row">
                  <div className="col">
                    <CanvasJSChart options={optionsCat} />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="table-responsive mt-4">
                      <table className="table table-bordered">
                        <tbody>
                          <tr align="center" className="view-table-tr-color">
                            <td width="175px">Categories</td>
                            <td width="175px">No. of Posts</td>
                            <td width="175px">%</td>
                          </tr>

                          {this.state.categories.map((category, i) => (
                            <tr align="center" key={i}>
                              <td>{category._id.category}</td>
                              <td>{category.count}</td>
                              <td>
                                {((category.count / total) * 100).toFixed(2)}%
                              </td>
                            </tr>
                          ))}

                          <tr className="font-weight-bold" align="center">
                            <td>Total</td>
                            <td>{total}</td>
                            <td>100.00%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
