// import packages
import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

// initiate state
const initialState = {
  title: "",
  subTitle: "",
  country: "",
  category: "",
  countries: [],
  date: "",
  mainImage: "",
  tumbImage: "",
  position: "",
  imgPosition: "",
  description: "",
  image: "",
  content: [],
  images: [],

  // edit
  id: "",
  editPosition: "",
  editDescription: "",
  editImage: "",

  success: "",
  errors: {},
  loading: false
};

export default class AddPost extends Component {
  state = initialState;

  componentDidMount = () => {
    // get countries
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/countries`)
      .then(countries => {
        if (countries.data.length > 0) {
          this.setState({ countries: countries.data });
        }
      });
  };

  // get input value
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // get file value
  handleChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  // add to content
  addToContent = e => {
    e.preventDefault();
    const newContent = {
      position: this.state.position,
      description: this.state.description
    };

    const content = [...this.state.content, newContent];

    const sortContent = content.sort((a, b) => {
      return a.position.localeCompare(b.position);
    });

    this.setState({ content: sortContent, position: "", description: "" });
  };

  // delete content item
  deleteContentItem = id => {
    const filterContent = this.state.content.filter((items, i) => i !== id);

    this.setState({
      content: filterContent
    });
  };

  // add to content
  addToImages = e => {
    e.preventDefault();
    const newContent = {
      position: this.state.imgPosition,
      image: this.state.image
    };

    const images = [...this.state.images, newContent];

    const sortImages = images.sort((a, b) => {
      return a.position.localeCompare(b.position);
    });

    this.setState({ images: sortImages, imgPosition: "", image: "" });

    // reset image value
    document.getElementById("image").value = "";
  };

  // delete content item
  deleteImageItem = id => {
    const filterImage = this.state.images.filter((items, i) => i !== id);

    this.setState({
      images: filterImage
    });
  };

  //
  takeExistingData = id => {
    const filterContent = this.state.content.filter((items, i) => i === id);

    console.log(filterContent);

    this.setState({
      id: id,
      editPosition: filterContent[0].position,
      editDescription: filterContent[0].description
    });
  };

  //
  takeExistingDataImage = id => {
    const filterImage = this.state.images.filter((items, i) => i === id);

    console.log(filterImage);

    this.setState({
      id: id,
      editPosition: filterImage[0].position,
      editImage: filterImage[0].image
    });
  };

  //
  updateDescription = id => {
    const filterOtherContent = this.state.content.filter(
      (items, i) => i !== id
    );

    const newObj = {
      position: this.state.editPosition,
      description: this.state.editDescription
    };

    const newContent = [...filterOtherContent, newObj];

    const sortContent = newContent.sort((a, b) => {
      return a.position.localeCompare(b.position);
    });

    this.setState({
      content: sortContent,
      editPosition: "",
      editDescription: ""
    });
  };

  //
  updateImage = id => {
    const filterOtherImages = this.state.images.filter((items, i) => i !== id);

    const newObj = {
      position: this.state.editPosition,
      image: this.state.editImage
    };

    const newImages = [...filterOtherImages, newObj];

    const sortImages = newImages.sort((a, b) => {
      return a.position.localeCompare(b.position);
    });

    this.setState({
      images: sortImages,
      editPosition: "",
      editImage: ""
    });

    document.getElementById("edit-image").value = "";
    // document.getElementById("updateImage").style.display = "none";
  };

  // add product
  handleSubmit = e => {
    e.preventDefault();

    // create product object
    var bodyFormData = new FormData();
    bodyFormData.set("title", this.state.title);
    bodyFormData.set("subTitle", this.state.subTitle);
    bodyFormData.set("country", this.state.country);
    bodyFormData.set("category", this.state.category);
    bodyFormData.set("date", this.state.date);
    bodyFormData.append("content", JSON.stringify(this.state.content));
    bodyFormData.append("mainImage", this.state.mainImage);
    bodyFormData.append("tumbImage", this.state.tumbImage);
    bodyFormData.set("imagesLeng", this.state.images.length);

    for (let i = 0; i < this.state.images.length; i++) {
      bodyFormData.set(`position_${i}`, this.state.images[i].position);
      bodyFormData.append(`images_${i}`, this.state.images[i].image);
    }

    // set loading
    this.setState({ loading: true });

    // send post req
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/post/${
        isAuthenticated().user._id
      }`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${isAuthenticated().token}`
      }
    })
      .then(result => {
        if (result) {
          // reset image value
          document.getElementById("mainImage").value = "";

          // set alert message
          this.setState({
            success: true,
            loading: false,
            title: "",
            subTitle: "",
            country: "",
            category: "",
            mainImage: "",
            tumbImage: "",
            position: "",
            imgPosition: "",
            description: "",
            image: "",
            content: [],
            images: [],
            id: "",
            editPosition: "",
            editDescription: ""
          });
        }
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  // close modal
  close = () => {
    this.setState({ success: false, errors: {} });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        <PageTitle title="Add Blog Post | Spritzer Blog" />
        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Post
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Post
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-1">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">ADD POST</h6>
                </div>

                {this.state.success ? (
                  <SweetAlert
                    success
                    title="Successfull"
                    onConfirm={this.close}
                    confirmBtnText="Ok"
                    confirmBtnStyle={{
                      background: "#f26666",
                      border: 0,
                      width: 70
                    }}
                  >
                    Post is added successfully.
                  </SweetAlert>
                ) : null}

                {this.state.errors.fail ? (
                  <SweetAlert
                    danger
                    title="Oops, Something went wrong"
                    onConfirm={this.close}
                    confirmBtnStyle={{ background: "#f26666", border: 0 }}
                  >
                    Post isn't added successfully.
                  </SweetAlert>
                ) : null}

                <form className="mt-3" noValidate>
                  <div className="form-group p-0 mt-4">
                    <label htmlFor="title">
                      Title <b className="text-danger">*</b>
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      className={classnames("form-control w-100", {
                        "is-invalid": this.state.errors.title
                      })}
                      value={this.state.title}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.title}
                    </div>
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="subTitle">Sub-Title</label>
                    <input
                      type="text"
                      name="subTitle"
                      id="subTitle"
                      className="form-control"
                      value={this.state.subTitle}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="country">
                      Country <b className="text-danger">*</b>
                    </label>

                    <select
                      name="country"
                      className={classnames("form-control input-position", {
                        "is-invalid": this.state.errors.country
                      })}
                      id="country"
                      placeholder="-- select --"
                      value={this.state.country}
                      onChange={this.handleChange}
                    >
                      <option></option>
                      {this.state.countries.map(countries => (
                        <option key={countries._id} value={countries._id}>
                          {countries.country}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors.country}
                    </div>
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="category">
                      Category <b className="text-danger">*</b>
                    </label>

                    <select
                      name="category"
                      className={classnames("form-control input-position", {
                        "is-invalid": this.state.errors.category
                      })}
                      id="category"
                      placeholder="-- select --"
                      value={this.state.category}
                      onChange={this.handleChange}
                    >
                      <option></option>
                      <option>Both</option>
                      <option>User</option>
                      <option>Business</option>
                    </select>
                    <div className="invalid-feedback">
                      {this.state.errors.category}
                    </div>
                  </div>
                  
                  <div className="form-group p-0 mt-4">
                    <label htmlFor="date">
                      Posting Date <b className="text-danger">*</b>
                    </label>

                    <input
                      name="date"
                      type="date"
                      className={classnames("form-control input-position", {
                        "is-invalid": this.state.errors.date
                      })}
                      id="date"
                      value={this.state.date}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.date}
                    </div>
                  </div>

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="mainImage">
                      Header Image <b className="text-danger">*</b>
                    </label>
                    <input
                      type="file"
                      className="d-block"
                      name="mainImage"
                      id="mainImage"
                      accept="image/jpeg, image/png"
                      onChange={this.handleChangeFile}
                    />
                    <div className="mt-1 invalid-error">
                      {this.state.errors.mainImage}
                    </div>
                  </div>
                  {this.state.mainImage && (
                    <div className="form-group p-0">
                      <img
                        src={URL.createObjectURL(this.state.mainImage)}
                        width="200"
                        alt="Front preview"
                      />
                    </div>
                  )}

                  <div className="form-group p-0 mt-4">
                    <label htmlFor="tumbImage">
                      Card Image <b className="text-danger">*</b>
                    </label>
                    <input
                      type="file"
                      className="d-block"
                      name="tumbImage"
                      id="tumbImage"
                      accept="image/jpeg, image/png"
                      onChange={this.handleChangeFile}
                    />
                    <div className="mt-1 invalid-error">
                      {this.state.errors.tumbImage}
                    </div>
                  </div>

                  {this.state.tumbImage && (
                    <div className="form-group p-0">
                      <img
                        src={URL.createObjectURL(this.state.tumbImage)}
                        width="200"
                        alt="Front preview"
                      />
                    </div>
                  )}
                  <label className="mt-3">
                    <b>Content</b>
                  </label>
                  <div style={{ padding: 10, background: "#d8d8d8" }}>
                    <div className="form-group p-0">
                      <label>
                        Position <b className="text-danger">*</b>
                      </label>
                      <input
                        type="Number"
                        name="position"
                        className="form-control input-position"
                        value={this.state.position}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group p-0 mt-4">
                      <label>
                        Description <b className="text-danger">*</b>
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        className="form-control"
                        value={this.state.description}
                        onChange={this.handleChange}
                      ></textarea>
                      <button
                        className="save-btn mt-3"
                        onClick={this.addToContent}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div
                    className="mt-4"
                    style={{ padding: 10, background: "#d8d8d8" }}
                  >
                    <div className="form-group p-0">
                      <label>
                        Position <b className="text-danger">*</b>
                      </label>

                      <input
                        type="Number"
                        name="imgPosition"
                        className="form-control input-position"
                        value={this.state.imgPosition}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group p-0 mt-4">
                      <label>
                        Image <b className="text-danger">*</b>
                      </label>

                      <input
                        type="file"
                        className="d-block"
                        name="image"
                        id="image"
                        accept="image/jpeg"
                        onChange={this.handleChangeFile}
                      />
                    </div>

                    {this.state.image && (
                      <div className="form-group p-0">
                        <img
                          src={URL.createObjectURL(this.state.image)}
                          width="200"
                          alt="Front preview"
                        />
                      </div>
                    )}
                    <button
                      className="save-btn mt-3"
                      onClick={this.addToImages}
                    >
                      Add
                    </button>
                  </div>

                  <div className="form-group p-0 mt-2">
                    <b className="text-danger" style={{ fontSize: "14px" }}>
                      * Denotes required.
                    </b>
                  </div>
                </form>

                {this.state.content.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      id="zero_config"
                      className="table table-hover table-bordered"
                    >
                      <thead>
                        <tr className="view-table-tr-color">
                          <td width="120">Position</td>
                          <td>Description</td>
                          <td width="110">Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.content.map((content, i) => (
                          <tr key={i}>
                            <td>{content.position}</td>
                            <td>{content.description}</td>
                            <td>
                              <button
                                type="submit"
                                className="btn btn-secondary btn-sm m-1"
                                data-toggle="modal"
                                data-target="#updateDescription"
                                onClick={() => this.takeExistingData(i)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </button>

                              <button
                                type="button"
                                className="btn btn-danger btn-sm m-1"
                                onClick={() => this.deleteContentItem(i)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}

                {this.state.images.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      id="zero_config"
                      className="table table-hover table-bordered"
                    >
                      <thead>
                        <tr className="view-table-tr-color">
                          <td width="120">Position</td>
                          <td>Image</td>
                          <td width="110">Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.images.map((image, i) => (
                          <tr key={i}>
                            <td>{image.position}</td>
                            <td>
                              <img
                                src={URL.createObjectURL(image.image)}
                                width="100"
                                alt="Front preview"
                              />
                            </td>
                            <td>
                              <button
                                type="submit"
                                className="btn btn-secondary btn-sm m-1"
                                data-toggle="modal"
                                data-target="#updateImage"
                                onClick={() => this.takeExistingDataImage(i)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </button>

                              <button
                                type="button"
                                className="btn btn-danger btn-sm m-1"
                                onClick={() => this.deleteImageItem(i)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}

                <div className="form-group p-0">
                  <button
                    className="save-btn mt-3"
                    type="submit"
                    name="submit"
                    onClick={this.handleSubmit}
                  >
                    {this.state.loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {this.state.loading ? " Saving..." : "Save"}
                  </button>
                </div>

                <div
                  className="modal fade"
                  id="updateDescription"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="update description"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="update-bank-model-heading">
                          Edit Description
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body pt-2">
                        <form>
                          <div className="form-row m-0">
                            <div className="col mb-2">
                              <label
                                htmlFor="edit-position"
                                className="normal-bold-text ml-0"
                              >
                                Position
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="edit-position"
                                name="editPosition"
                                value={this.state.editPosition}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col mb-2">
                              <label>Description</label>

                              <textarea
                                type="text"
                                name="editDescription"
                                className="form-control w-100"
                                value={this.state.editDescription}
                                onChange={this.handleChange}
                              ></textarea>
                            </div>
                          </div>
                        </form>
                        <button
                          data-dismiss="modal"
                          aria-label="Close"
                          className="save-btn w-100 mx-auto mt-3 mb-0"
                          onClick={() => this.updateDescription(this.state.id)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="updateImage"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="update image"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="update-bank-model-heading">
                          Edit Image
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body pt-2">
                        <form>
                          <div className="form-row m-0">
                            <div className="col mb-2">
                              <label
                                htmlFor="edit-position"
                                className="normal-bold-text ml-0"
                              >
                                Position
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="edit-position"
                                name="editPosition"
                                value={this.state.editPosition}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="col mb-2">
                              <label>Image</label>

                              <input
                                type="file"
                                className="d-block"
                                name="editImage"
                                id="edit-image"
                                accept="image/jpeg"
                                onChange={this.handleChangeFile}
                              />
                              {this.state.editImage ? (
                                <img
                                  src={URL.createObjectURL(
                                    this.state.editImage
                                  )}
                                  className="mt-3"
                                  width="200"
                                  alt="blog post"
                                />
                              ) : null}
                            </div>
                          </div>
                        </form>
                        <button
                          data-dismiss="modal"
                          aria-label="Close"
                          className="save-btn w-100 mx-auto mt-3 mb-0"
                          onClick={() => this.updateImage(this.state.id)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
