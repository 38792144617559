// import packages
import React, { Component } from "react";
import { Link } from "react-router-dom";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

export default class AdminProfile extends Component {
  render() {
    // get authenticated user info
    const {
      user: { fName, image, email }
    } = isAuthenticated();

    return (
      <div className="container-fluid p-0">
        <PageTitle title="Admin Profile | Spritzer Blog" />
        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Profile
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  View Profile
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-3">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">PROFILE</h6>
                </div>

                <div className="table-responsive mt-4">
                  <table className="table text-left table-hover col-6">
                    <tbody className="table-striped ">
                      <tr>
                        <td>
                          <img src={image} width="150px" alt={fName} />
                        </td>
                        <td style={{ paddingTop: "40px" }}>
                          <h2 className="text-capitalize">Owner</h2>
                          <p
                            className="text-capitalize"
                            style={{ fontSize: "18px" }}
                          >
                            {fName}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Mail</b>
                        </td>
                        <td>{email}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Link
                            to={`/dashboard/update-admin-profile/${
                              isAuthenticated().user._id
                            }`}
                            className="save-btn"
                            style={{ width: "120px" }}
                          >
                            Edit Profile
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
