// import packages
import React from "react";

export default function PageNotFound() {
  return (
    <div style={{ background: "f26666" }}>
      <h1 style={{ color: "#fff" }}>404 Page Not Found</h1>
    </div>
  );
}
