// import packages
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Lines } from "react-preloaders";
import moment from "moment";

// import components
import Navbar from "../../components/client/Navbar";
import Footer from "../../components/client/Footer";
import PageTitle from "../../components/PageTitle";

export default class SingleBlog extends Component {
  state = {
    region: localStorage.getItem("regionName") ? localStorage.getItem("regionName") : "",
    countryCode: localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : "",
    CountryName: localStorage.getItem("CountryName") ? localStorage.getItem("CountryName") : "",

    id: "",
    title: "",
    subTitle: "",
    content: [],
    date: "",

    // pre loading
    loading: true
  };

  componentDidMount() {

    if (!localStorage.getItem("regionName")){
      // get all regions
    axios
    .get(`https://api.spritzer.app/services/regions`)
    .then(regions => {
      // filter regions which are isAvailable === true
      const filterRegions = regions.data.filter(filter => {
        return filter.isAvailable === true;
      });

      // get IP info
      axios
        .get(`https://api.spritzer.app/services/ipinfo`, {
          headers: {
            secret: `7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS`
          }
        })
        .then(ipInfo => {
          var CountryName = "";
          var region = "";
          var countryCode = "";

          // filter by country code
          const countryCodeShort = filterRegions.filter(
            codeShort =>
              codeShort.country.countryCodeShort === ipInfo.data.countryCode
          );

          // identify country code
          if (countryCodeShort.length > 1) {
            var regionCode = countryCodeShort.filter(
              code => code.regionCode === ipInfo.data.regionCode
            );
            CountryName = regionCode[0].country.countryName;
            region = regionCode[0].regionCode;
            countryCode = regionCode[0].country.countryCodeShort;
          } else if (countryCodeShort.length === 1) {
            CountryName = countryCodeShort[0].country.countryName;
            region = countryCodeShort[0].regionCode;
            countryCode = countryCodeShort[0].country.countryCodeShort;
          } else {
            CountryName = "Australia";
            region = "VIC";
            countryCode = "AU";
          }

          // store in local storage
          localStorage.setItem("CountryName", CountryName);
          localStorage.setItem("regionName", region);
          localStorage.setItem("countryCode", countryCode);

          this.setState({ CountryName, region, countryCode });

        })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
  }

    // get all products
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/post/${this.props.match.params.id}`
      )
      .then(post => {
        const sortByPosition = post.data.content.sort((a, b) => {
          return a.position - b.position;
        });

        this.setState({
          id: post.data._id,
          title: post.data.title,
          subTitle: post.data.subTitle,
          date: post.data.date,
          content: sortByPosition,
          loading: false
        });
      });
  }

  // change region
  changeRegion = (CountryName, region, countryCode) => {
    this.setState({ CountryName, region, countryCode });

    // store in local storage
    localStorage.setItem("CountryName", CountryName);
    localStorage.setItem("regionName", region);
    localStorage.setItem("countryCode", countryCode);

    // get all posts
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/posts?sortBy=date&country=${countryCode}`
      )
      .then(posts => {
        this.setState({
          posts: posts.data,
          loading: false
        });
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <React.Fragment>
        <PageTitle title={`${this.state.title} | Spritzer Blog`} />

        <Navbar />

        <Lines
          color="#fff"
          background="#f26666"
          animation="slide-down"
          customLoading={this.state.loading}
        />

        <div className="container-fluid px-0 pb-0 jumbotron-img-container" style={{position: "relative"}}>
          <img
            src={`${process.env.REACT_APP_API_URL}/api/post/photo/${this.state.id}`}
            width="100%"
            className="m-0 d-none d-sm-block"
            alt={this.state.title}
          />
          <img
            src={`${process.env.REACT_APP_API_URL}/api/post/card/photo/${this.state.id}`}
            width="100%"
            className="m-0 d-block d-sm-none"
            alt={this.state.title}
          />
          <Link to="/" className="back-to-blog-link-header">
          <i className="fas fa-arrow-left"></i> BACK TO BLOG
          </Link>
        </div>
        <div
          className="jumbotron px-0 pb-4 m-0 pt-4"
          style={{ borderRadius: 0 }}
        >
          <div className="single-post-header-container">

              <h2 className="post-title">{this.state.title}</h2>
              <Link to="/" className="back-to-blog-link">
              <i className="fas fa-arrow-left"></i> BACK TO BLOG
              </Link>
          </div>
        </div>

        <div
          className="container-fluid px-0 pb-4"
          style={{ background: "#f9f9f9" }}
        >
          {/* <img
            src={`${process.env.REACT_APP_API_URL}/api/post/photo/${this.state.id}`}
            width="100%"
            className="m-0"
            alt={this.state.title}
          /> */}
          <div className="container single-post-container">
            <div className="row m-0">
              <div className="col">
                {this.state.subTitle ? (
                  <p className="mt-4">{this.state.subTitle}</p>
                ) : null}

                {this.state.content.map((item, i) => (
                  <div key={i}>
                    {item.description ? (
                      item.description.length <= 55 ? (
                        <p className="post-heading mt-4">{item.description}</p>
                      ) : (
                        <p className="mt-3">{item.description}</p>
                      )
                    ) : null}
                    {item.image ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/api/post/content/photo/${this.state.id}/${item._id}`}
                        width="100%"
                        className="py-2"
                        alt="blog"
                      />
                    ) : null}
                  </div>
                ))}
                {this.state.date ? (
                  <p className="posting-date mt-4">
                    <span className="published">Published</span>{" "}
                    {moment(this.state.date).format("Do MMMM, YYYY")}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <Footer
          CountryName={this.state.CountryName}
          countryCode={this.state.countryCode}
          changeRegion={this.changeRegion}
        />
      </React.Fragment>
    );
  }
}
