import React, { Component } from "react";

export default class Pagination extends Component {
  state = {};

  render() {
    // page numbers
    const pageNumbers = [];

    for (
      let i = 1;
      i <= Math.ceil(this.props.noOfAllItems / this.props.itemsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <React.Fragment>
        {pageNumbers.length > 1 ? (
          <div className="row m-0">
            <div className="col">
              <nav
                aria-label="Page navigation"
                className="shop-pagination mt-4 mr-1"
              >
                <ul className="pagination">
                  {pageNumbers.map((numbers, i) => (
                    <li className="page-item" key={i}>
                      <button
                        className="page-link"
                        style={
                          this.props.currentPage === numbers
                            ? { background: "#f26666", color: "#fff" }
                            : {}
                        }
                        onClick={() => this.props.handlePage(numbers)}
                      >
                        {numbers}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
