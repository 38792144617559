// import packages
import React, { Component } from "react";
import axios from "axios";
import moment from "moment"

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

export default class ViewPost extends Component {
  state = {
    id: "",
    title: "",
    subTitle: "",
    content: [],
    date: "",
  };

  componentDidMount() {
    // get all products
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/post/${this.props.match.params.id}`
      )
      .then(post => {
        const sortByPosition = post.data.content.sort((a, b) => {
          return a.position - b.position;
        });

        this.setState({
          id: post.data._id,
          title: post.data.title,
          subTitle: post.data.subTitle,
          content: sortByPosition
        });
      });
  }

  render() {
    return (
      <div className="container-fluid p-0">
        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Post
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  View/Edit/Delete Post
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content p-0">
              <div className="container-fluid bg-white p-0">
                <div className="row pt-2 page-heading-sec m-0">
                  <h6 className="col-12">VIEW POST</h6>
                </div>

                <div className="container-fluid px-0 pb-0 jumbotron-img-container">
          <img
            src={`${process.env.REACT_APP_API_URL}/api/post/photo/${this.state.id}`}
            width="100%"
            className="m-0"
            alt={this.state.title}
          />
        </div>
        <div
          className="jumbotron px-0 pb-4 m-0 pt-4"
          style={{ borderRadius: 0 }}
        >
          <div className="container">
            <div className="post-title-block">
              <h2 className="post-title">{this.state.title}</h2>
            </div>
          </div>
        </div>

        <div
          className="container-fluid px-0 pb-4"
          style={{ background: "#f9f9f9" }}
        >
          {/* <img
            src={`${process.env.REACT_APP_API_URL}/api/post/photo/${this.state.id}`}
            width="100%"
            className="m-0"
            alt={this.state.title}
          /> */}
          <div className="container single-post-container">
            <div className="row m-0">
              <div className="col">
                {this.state.subTitle ? (
                  <p className="post-sub-title mt-4">{this.state.subTitle}</p>
                ) : null}

                {this.state.content.map((item, i) => (
                  <div key={i}>
                    {item.description ? 
                    
                    item.description.length <= 55 ? (
                      <p className="post-heading mt-4">
                        {item.description}
                      </p>
                    ) :
                    
                    (
                      <p className="mt-3">
                        {item.description}
                      </p>
                    ) : null}
                    {item.image ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/api/post/content/photo/${this.state.id}/${item._id}`}
                        width="100%"
                        className="py-2"
                        alt="blog"
                      />
                    ) : null}
                  </div>
                ))}
                {this.state.date ? (
                  <p className="posting-date mt-4"><span className="published">Published</span> {moment(this.state.date).format("Do, MMMM YYYY")}</p>
                ) : null}

              </div>
            </div>
          </div>
        </div>
        
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
