exports.isAuthenticated = () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("jwt"));
      } else {
        return false;
      }
    } else {
      return false;
    }
  };