// import packages
import React, { Component } from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// import components
import Navbar from "../../components/admin/Navbar";
import Sidebar from "../../components/admin/Sidebar";
import PageTitle from "../../components/PageTitle";

// load isAuthenticated method
import { isAuthenticated } from "../../auth/auth.js";

export default class ViewAdminUsers extends Component {
  state = {
    adminUsers: [],
    deleteSuccess: false
  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/adminUsers`)
      .then(admins => {
        if (admins.data.length > 0) {
          this.setState({
            adminUsers: admins.data
          });
        }
      });
  }

  handleDelete = id => {
    axios.delete(
      `${process.env.REACT_APP_API_URL}/api/adminUser/${id}/${
        isAuthenticated().user._id
      }`,
      {
        headers: { Authorization: `Bearer ${isAuthenticated().token}` }
      }
    );

    const filteradminUsers = this.state.adminUsers.filter(
      adminUsers => adminUsers._id !== id
    );

    this.setState({
      adminUsers: filteradminUsers,
      deleteSuccess: true
    });
  };

  // close modal
  close = () => {
    this.setState({ deleteSuccess: false });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        <PageTitle title="View Admin Users | Spritzer Blog" />

        <Navbar />
        <div className="row m-0">
          <Sidebar />

          <div className="content-col">
            <nav aria-label="breadcrumb" className="inner-breadcrumb">
              <ol className="breadcrumb rounded-0 mb-0">
                <li className="breadcrumb-item" aria-current="page">
                  Settings
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  View Admins
                </li>
              </ol>
            </nav>

            <div className="container-fluid inner-content py-md-3">
              <div className="container-fluid bg-white pb-3">
                <div className="row pt-2 page-heading-sec">
                  <h6 className="col-12">VIEW ADMINS</h6>
                </div>

                {this.state.deleteSuccess ? (
                  <SweetAlert
                    success
                    title="Successfull"
                    onConfirm={this.close}
                    confirmBtnText="Ok"
                    confirmBtnStyle={{
                      background: "#423c59",
                      border: 0,
                      width: 70
                    }}
                  >
                    Admin user has been deleted successfully
                  </SweetAlert>
                ) : null}

                <div className="table-responsive mt-4">
                  <table
                    id="zero_config"
                    className="table table-hover table-bordered"
                  >
                    <thead>
                      <tr className="view-table-tr-color">
                        <td align="center" width="150">
                          Image
                        </td>
                        <td>Admin Name</td>
                        <td align="center" width="150">
                          Email
                        </td>
                        <td style={{ width: "110px" }}>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.adminUsers.map((adminUser, i) => (
                        <tr key={i}>
                          <td align="center">
                            <img
                              src={adminUser.image}
                              alt={adminUser.fName}
                              width="60px"
                              className="py-1"
                            />
                          </td>
                          <td>{adminUser.fName}</td>
                          <td>{adminUser.email}</td>

                          <td>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm m-1"
                              onClick={() => this.handleDelete(adminUser._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
