import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Flag from "react-country-flags";
import "../../css/client/FooterStyle.css";
import apple from "../../images/icon-apple-store--black.png";
import google from "../../images/icon-google-play--black.png";
import logo from "../../images/spritzer-logo.png";

export default class Footer extends Component {
  state = {
    countryCode: "",
    regions: []
  };

  componentDidMount() {
    // get all regions
    axios
      .get(`https://api.spritzer.app/services/regions`)
      .then(regions => {
        // filter regions which are isAvailable === true
        const filterRegions = regions.data.filter(filter => {
          return filter.isAvailable === true;
        });

        // sort region by Alphabetical Order
        const sortAZOrder = filterRegions.sort((a, b) => {
          return a.country.countryName.localeCompare(b.country.countryName);
        });

        this.setState({ regions: sortAZOrder });
      })
      .catch(err => console.log(err));
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid footer-sec">
          <div className="row m-0">
            <div className="col p-0">
              <h4 className="text-center text-white mb-4">
                Download the App on iPhone or Android.
              </h4>
              <div className="store-div">
                <a
                  href="https://itunes.apple.com/au/app/spritzer-happy-hours/id1457489918?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={apple} className="store-icons" alt="play store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.spritzer.user"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={google} className="store-icons" alt="play store" />
                </a>
              </div>
              <p className="text-center">
                <a
                  className="social-media-icon"
                  href={
                    this.props.countryCode === "LK"
                      ? "https://www.facebook.com/spritzer.srilanka"
                      : "https://www.facebook.com/Spritzer.Australia"
                  }
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook social-media-icon"></i>
                </a>{" "}
                <a
                  className="social-media-icon"
                  href={
                    this.props.countryCode === "LK"
                      ? "https://www.instagram.com/spritzer_sl"
                      : "https://www.instagram.com/spritzer_au"
                  }
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram social-media-icon"></i>
                </a>{" "}
              </p>
              <hr
                style={{ borderColor: "#fff", margin: "auto", width: "80%" }}
              />
            </div>
          </div>
          <div className="row m-0">
            <div className="col-sm p-0">
              <Link to="/">
                <img
                  src={logo}
                  width="170"
                  alt="ransis arcade logo"
                  className="py-3 pr-3 footer-logo"
                />
              </Link>
            </div>
            <div className="col-sm p-0">
              {/* <div className="float-right">
                
              </div> */}
              <div className="copyright mt-4"><div className="btn-group dropup update-region">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <Flag
                      country={
                        this.props.countryCode &&
                        this.props.countryCode.toLowerCase()
                      }
                      asSquare={true}
                      className="country-flag"
                    />
                    <span className="d-none d-sm-inline">
                      {this.props.CountryName}
                    </span>{" "}
                    ({this.props.countryCode})
                  </button>
                  <div className="dropdown-menu">
                    {this.state.regions.map((region, i) => (
                      <p
                        className="dropdown-item mb-0"
                        key={i}
                        onClick={() =>
                          this.props.changeRegion(
                            region.country.countryName,
                            region.regionCode,
                            region.country.countryCodeShort
                          )
                        }
                      >
                        <Flag
                          country={region.country.countryCodeShort.toLowerCase()}
                          asSquare={true}
                          className="country-flag"
                        />
                        {region.country.countryName} ({region.regionCode})
                      </p>
                    ))}
                  </div>
                </div> &nbsp;
              &copy; Spritzer 2020</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
